import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookie from "universal-cookie";

const cookies = new Cookie();

function Authentication(props) {
  useEffect(() => {
    document.title = "NIC | Admin";
  }, []);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const login = async () => {
    try {
      const userData = {
        name: name,
        password: password,
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/users/signIn`,
        userData,
        { withCredentials: true }
      );
      cookies.set("jwt", data.jwt, {
        path: "/",
        signed: false,
        httpOnly: false,
      });
      props.setCurrentUser(data.user);
      alert(data.user.name + " logged in");
      setTimeout(() => {
        alert("The session has expired.Please log in again");
        cookies.remove("jwt");
        window.location.reload();
      }, 1 * 60 * 60 * 1000);
    } catch (err) {
      alert(err.response.data.error);
    }
  };

  return (
    <div className="antialiased bg-gray-200 text-gray-900 font-sans">
      <div className="flex items-center h-screen w-full">
        <div className="w-full bg-white rounded shadow-lg p-8 m-4 md:max-w-sm md:mx-auto">
          <span className="block w-full text-xl uppercase font-bold mb-4">
            Login
          </span>
          <div className="mb-4">
            <div className="mb-4 md:w-full">
              <label htmlFor="em    ail" className="block text-xs mb-1">
                Username
              </label>
              <input
                className="w-full border rounded p-2 outline-none focus:shadow-outline"
                type="name"
                name="name"
                id="name"
                placeholder="Username"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-6 md:w-full">
              <label htmlFor="password" className="block text-xs mb-1">
                Password
              </label>
              <input
                className="w-full border rounded p-2 outline-none focus:shadow-outline"
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button
              className="bg-main hover:opacity-80 text-white uppercase text-sm font-semibold px-4 py-2 rounded"
              onClick={login}
            >
              Login
            </button>
          </div>
          <a
            className="text-blue-700 text-center text-sm"
            href="/admin"
            onClick={() => alert("Just Call Me - 09695716390")}
          >
            Forgot password?
          </a>
        </div>
      </div>
    </div>
  );
}

export default Authentication;
