import HomePage from "./Pages/Homepage";
import { Routes, Route, Outlet } from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop";
import axios from "axios";
import Loader from "./components/Loader";
import PageLayout from "./components/PageLayout";
import { useEffect, useState } from "react";

import SingleNews from "./Pages/SingleNews";
import News from "./Pages/News";
import Admin from "./admin/Admin";
import MenuBar from "./components/MenuBar";
import Footer from "./components/Footer";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Programs from "./Pages/Programs";
import MbaUCAM from "./Pages/courses/Mba_UCAM";
import MbaCU from "./Pages/courses/Mba_CU";
import MbaEgs from "./Pages/courses/Mba_EGS";
import BbaCU from "./Pages/courses/Bba_CU";
import BbaEGS from "./Pages/courses/Bba_EGS";
import BbaTopUpUCAM from "./Pages/courses/Bba_Topup_UCAM";
import BbaTopUpEGS from "./Pages/courses/Bba_Topup_EGS";
import BbaTopUpCU from "./Pages/courses/Bba_Topup_CU";
import BbaPmp from "./Pages/courses/Bba_pmp_EGS";
import BpaPPA from "./Pages/courses/Bpa_PPA";
import BaCdIcan from "./Pages/courses/Ba_CD_ican";
import BscEGS from "./Pages/courses/BSc_egs";
import Diploma from "./Pages/courses/dip";

function App() {
  const [allnews, setAllnews] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/news`
      );
      setAllnews(data);
    };
    fetchData().catch((err) => console.log(err));
  }, []);
  if (allnews === null) {
    return <Loader />;
  }
  return (
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<OutletLayout />}>
          <Route index element={<HomePage allnews={allnews} />} />
          <Route path="news" element={<Outlet />}>
            <Route index element={<News allnews={allnews} />} />
            <Route path=":newsId" element={<SingleNews />} />
          </Route>
          <Route
            path="about"
            element={<PagelayoutOutlet title="About NIC" allnews={allnews} />}
          >
            <Route index element={<About />} />
          </Route>
          <Route
            path="contact"
            element={<PagelayoutOutlet title="Contact Us" allnews={allnews} />}
          >
            <Route index element={<Contact />} />
          </Route>
          <Route path="programs" element={<Programs />} />
          <Route path="admission" element={<Outlet />}>
            <Route
              path="mba_ucam"
              element={
                <PagelayoutOutlet
                  title="Master Of Business Administration (UCAM)"
                  allnews={allnews}
                />
              }
            >
              <Route index element={<MbaUCAM />} />
            </Route>
            <Route
              path="mba_cu"
              element={
                <PagelayoutOutlet
                  title="Master Of Business Administration (CU)"
                  allnews={allnews}
                />
              }
            >
              <Route index element={<MbaCU />} />
            </Route>
            <Route
              path="mba_egs"
              element={
                <PagelayoutOutlet
                  title="Master Of Business Administration (EGS)"
                  allnews={allnews}
                />
              }
            >
              <Route index element={<MbaEgs />} />
            </Route>
            <Route
              path="bbaf_cu"
              element={
                <PagelayoutOutlet
                  title="Bachelor of Business Administration (CU)"
                  allnews={allnews}
                />
              }
            >
              <Route index element={<BbaCU />} />
            </Route>
            <Route
              path="bbaf_egs"
              element={
                <PagelayoutOutlet
                  title="Bachelor of Business Administration (EGS)"
                  allnews={allnews}
                />
              }
            >
              <Route index element={<BbaEGS />} />
            </Route>
            <Route
              path="bbat_ucam"
              element={
                <PagelayoutOutlet
                  title="Bachelor of Business Administration (Top Up) (UCAM)"
                  allnews={allnews}
                />
              }
            >
              <Route index element={<BbaTopUpUCAM />} />
            </Route>
            <Route
              path="bbat_cu"
              element={
                <PagelayoutOutlet
                  title="Bachelor of Business Administration (Top Up) (CU)"
                  allnews={allnews}
                />
              }
            >
              <Route index element={<BbaTopUpCU />} />
            </Route>
            <Route
              path="bbat_egs"
              element={
                <PagelayoutOutlet
                  title="Bachelor of Business Administration (Top Up) (EGS)"
                  allnews={allnews}
                />
              }
            >
              <Route index element={<BbaTopUpEGS />} />
            </Route>
            <Route
              path="pmp_egs"
              element={
                <PagelayoutOutlet
                  title="Bachelor of Business Administration in PMP (Top-Up) (EGS)"
                  allnews={allnews}
                />
              }
            >
              <Route index element={<BbaPmp />} />
            </Route>
            <Route
              path="bpa_ppa"
              element={
                <PagelayoutOutlet
                  title="Bachelor in Professional Accounting (PPA)"
                  allnews={allnews}
                />
              }
            >
              <Route index element={<BpaPPA />} />
            </Route>
            <Route
              path="creative_ican"
              element={
                <PagelayoutOutlet
                  title="Bachelor of Art in Creative Design (ICAN)"
                  allnews={allnews}
                />
              }
            >
              <Route index element={<BaCdIcan />} />
            </Route>
            <Route
              path="cs_esgi"
              element={
                <PagelayoutOutlet
                  title="BSc in Computer Science/ Artificial Intelligence (ESGI)"
                  allnews={allnews}
                />
              }
            >
              <Route index element={<BscEGS />} />
            </Route>
            <Route
              path="iqn"
              element={
                <PagelayoutOutlet
                  title="Diploma and Certification Courses (IQN)"
                  allnews={allnews}
                />
              }
            >
              <Route index element={<Diploma />} />
            </Route>
          </Route>
        </Route>
        <Route path="admin" element={<Outlet />}>
          <Route index element={<Admin />} />
        </Route>

        {/* <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} /> */}
      </Routes>
    </ScrollToTop>
  );
}

const PagelayoutOutlet = ({ title, allnews }) => {
  return (
    <PageLayout title={title} allnews={allnews}>
      <Outlet />
    </PageLayout>
  );
};

const OutletLayout = () => {
  return (
    <>
      <MenuBar />
      <Outlet />
      <Footer />
    </>
  );
};

export default App;
