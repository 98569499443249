import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import Heading from "./Heading";

const PageLayout = (props) => {
  let news = [];
  let max;
  if (props.allnews.length < 3) {
    max = props.allnews.length;
  } else {
    max = 3;
  }
  for (let i = 0; i < max; i++) {
    news.push(props.allnews[i]);
  }
  if (news) {
    return (
      <div className="max-w-7xl py-10 mx-auto flex md:flex-row justify-evenly flex-col">
        <div className="md:max-w-4xl max-w-sm px-3 py-5 mx-auto flex-1 md:my-32 mt-32 overflow-hidden ">
          <Heading title={props.title} />
          {props.children}
        </div>

        {/* Latest News */}
        <div className="md:w-1/4 w-4/5 px-5 mx-auto my-5 md:my-32 ">
          <Heading smallHeading title="Latest News" />
          <div className="mt-5">
            {news.map((singleNews, index) => (
              <Link to={`/news/${singleNews.id}`} key={index}>
                <div className="border-b-2 border-main py-3 flex flex-row gap-3 hover:bg-gray-300 hover:bg-opacity-80 px-1">
                  <div className="w-20">
                    <img
                      className="object-fill w-full h-full "
                      src={singleNews.imageUrl}
                      alt="college campus"
                    />
                  </div>
                  <div className="flex-1 flex flex-col gap-3">
                    <div className="text-xs text-gray-500">
                      {moment(singleNews.date).format("Do MMMM YYYY")}
                    </div>
                    <div className="text-sm">{singleNews.title}</div>
                  </div>
                </div>
              </Link>
            ))}

            <Link to="/news">
              <div className="bg-main rounded-md w-full text-center text-white p-2 mt-3 hover:opacity-70 hover:cursor-pointer">
                View More
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
};

export default PageLayout;
