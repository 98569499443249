import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div id="footer-items">
      <div className="bg-slate-800 text-white py-10 md:px-20 px-5 mx-auto font-thin font-nav">
        {/* top part of footer */}
        <div className="md:flex md:flex-row grid grid-cols-2 gap-3 md:gap-0 justify-around md:text-base text-xs">
          <div className="">
            <ul>
              <li>
                <p className="font-bold text-base md:text-lg">NIC</p>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/Contact">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="">
            <ul>
              <li>
                <p className="font-bold text-base md:text-lg">Programs</p>
              </li>
              <li>
                <Link to="/admission/mba_ucam">M.Ba (UCAM) </Link>
              </li>
              <li>
                <Link to="/admission/bbaf_egs">B.Ba (EGS) </Link>
              </li>
              <li>
                <Link to="/admission/creative_ican">
                  Creative Design (ICAN)
                </Link>
              </li>
            </ul>
          </div>
          <div className=" ">
            <ul>
              <li>
                <p className="font-bold text-base md:text-lg">Others</p>
              </li>
              <li>
                <Link to="/news">News & Announcements </Link>
              </li>
            </ul>
          </div>
          <div>
            <p className="font-bold text-base md:text-lg"> Connect with Us</p>
            <div className="flex flex-col">
              <div className="mt-2">
                <div className="flex flex-row mt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 my-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                  </svg>
                  <a href="tel:09-881-20-44-66" className="ml-3">
                    {" "}
                    09 881 20 44 66{" "}
                  </a>
                </div>
                <div className="flex flex-row mt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 my-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                  </svg>
                  <a href="tel:09-881-20-44-77" className="ml-3">
                    {" "}
                    09 881 20 44 77{" "}
                  </a>
                </div>
                <div className="flex md:flex-row flex-col mt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 my-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <a
                    href="mailto:connect@nicmyanmar.com"
                    className="ml-3 break-words md:text-base text-xs"
                  >
                    connect@nicmyanmar.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* bottom part of footer */}
        <div className="flex md:flex-row flex-col justify-around md:mt-10 mt-2 items-center">
          <div className="md:w-80 md:h-80 w-60 h-60 grid items-center">
            <img src="./assets/NIC-2.png" alt="APMI" />
          </div>
          <div className="md:w-auto w-full">
            <p className="text-xl font-semibold text-center"> Our Partners</p>
            <Partners />
          </div>
        </div>
      </div>
      <div className="bg-main p-5 text-white text-center">
        Copyright © 2021 Newton International College. All Rights Reserved.
      </div>
    </div>
  );
};

const Partners = () => {
  return (
    <div className="grid grid-cols-4 gap-5 mt-5 mx-auto">
      <div className="partner-logo">
        <a
          href="https://international.ucam.edu/"
          target="_blank"
          rel="noreferrer"
        >
          <img src="./assets/Partners/logo_ucam_blanco_v.png" alt="UCAM" />
        </a>
      </div>{" "}
      <div className="partner-logo">
        <a href="https://www.egs.education/" target="_blank" rel="noreferrer">
          <img src="./assets/Partners/egs.png" alt="EGS" />
        </a>
      </div>{" "}
      <div className="partner-logo">
        <a href="https://charisma.edu.eu/" target="_blank" rel="noreferrer">
          <img src="./assets/Partners/cu.png" alt="CU" />
        </a>
      </div>
      <div className="partner-logo">
        <a href="https://www.esgi.fr/" target="_blank" rel="noreferrer">
          <img src="./assets/Partners/ESGI.png" alt="ESGI" />
        </a>
      </div>
      <div className="partner-logo bg-slate-100">
        <a
          href="https://www.ppa.fr/ecole-commerce-alternance.html"
          target="_blank"
          rel="noreferrer"
        >
          <img src="./assets/Partners/ppa transparent.png" alt="PPA" />
        </a>
      </div>
      <div className="partner-logo">
        <a href="https://www.ican-design.fr/" target="_blank" rel="noreferrer">
          {" "}
          <img src="./assets/Partners/ICAN-logo-home.png" alt="ICAN" />
        </a>
      </div>
      <div className="partner-logo">
        <a href="https://iqnglobal.com/" target="_blank" rel="noreferrer">
          <img src="./assets/Partners/IQN-1.png" alt="IQN" />
        </a>
      </div>
      <div className="partner-logo">
        <a href="http://www.apmi.org.uk/" target="_blank" rel="noreferrer">
          <img src="./assets/Partners/APMI.png" alt="APMI" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
