import { useState } from "react";

const ParentProgress = ({ header, children }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className=" relative" onClick={() => setOpen(!open)}>
      <div className="py-3 px-3 font-body bg-gray-300 text-sm">
        <div className="flex flex-row justify-between items-center">
          <div>{header}</div>
          <i
            className={`fas fa-chevron-down  float-right transform origin-center ${
              open ? "rotate-180" : "rotate-0"
            } duration-700 ease-in-out`}
          ></i>
        </div>
      </div>
      <div
        className={` ${
          !open ? "max-h-0" : "max-h-maximum"
        } relative overflow-hidden text-gray-800 text-base  transition-all duration-700 ease-in-out`}
      >
        <div className="md:px-5 px-2 py-2 transition-all duration-500 ">
          {children}
        </div>
      </div>
    </div>
  );
};

export default ParentProgress;
