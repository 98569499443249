import Heading from "../components/Heading";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ParentProgress = ({ header, children }) => {
  const [open, setOpen] = useState(false);
  return (
    <div
      className="my-4 relative cursor-pointer"
      onClick={() => setOpen(!open)}
    >
      <div className="shadow-md py-5 px-3  md:text-xl text-lg font-body font-light  bg-slate-300 text-gray-700  rounded-md ">
        <div className="flex flex-row justify-between">
          <div>{header}</div>
          <i
            className={`fas fa-chevron-down  my-auto float-right transform origin-center ${
              open ? "rotate-180" : "rotate-0"
            } duration-700 ease-in-out`}
          ></i>
        </div>{" "}
        <div
          className={` ${
            !open ? "max-h-0" : "max-h-maximum"
          } relative overflow-hidden text-gray-800 text-lg origin-top transition-all duration-500 ease-in-out`}
        >
          <div className="mt-2 px-5 py-2 transition-all duration-500 ">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

function Programs() {
  useEffect(() => {
    document.title = "NIC | Programs";
  }, []);
  return (
    <div className="container py-10 bg-gray-200 mx-auto">
      <div className="md:max-w-6xl max-w-sm px-3 mx-auto my-32">
        <Heading title="Programs We Offer" />
        <div className="mb-12">
          <ParentProgress header="Master of Business Administration">
            <ul>
              <li className="mb-2">
                <Link
                  to="/admission/mba_ucam"
                  className="hover:underline hover:text-main "
                >
                  - Catholic University of Murcia (UCAM,Spain)
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/admission/mba_cu"
                  className="hover:underline hover:text-main "
                >
                  - Charisma University (CU,United Kingdom)
                </Link>
              </li>
              <li className="">
                <Link
                  to="/admission/mba_egs"
                  className="hover:underline hover:text-main "
                >
                  - European Global School (EGS,France)
                </Link>
              </li>
            </ul>
          </ParentProgress>
          <ParentProgress header="Bachelor of Business Administration(Full Year)">
            <ul>
              <li className="mb-2">
                <Link
                  to="/admission/bbaf_cu"
                  className="hover:underline hover:text-main "
                >
                  - Charisma University (CU,United Kingdom)
                </Link>
              </li>
              <li className="">
                <Link
                  to="/admission/bbaf_egs"
                  className="hover:underline hover:text-main "
                >
                  - European Global School (EGS,France)
                </Link>
              </li>
            </ul>
          </ParentProgress>
          <ParentProgress header="Bachelor of Business Administration(Top-Up)">
            <ul>
              <li className="mb-2">
                <Link
                  to="/admission/bbat_ucam"
                  className="hover:underline hover:text-main "
                >
                  - Catholic University of Murcia (UCAM,Spain)
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/admission/bbat_cu"
                  className="hover:underline hover:text-main "
                >
                  - Charisma University (CU,United Kingdom)
                </Link>
              </li>
              <li className="">
                <Link
                  to="/admission/bbat_egs"
                  className="hover:underline hover:text-main "
                >
                  - European Global School (EGS,France)
                </Link>
              </li>
            </ul>
          </ParentProgress>
          <ParentProgress header="BBA in PMP (Top-Up)">
            {" "}
            <ul>
              <li className="">
                <Link
                  to="/admission/pmp_egs"
                  className="hover:underline hover:text-main "
                >
                  - European Global School (EGS,France)
                </Link>
              </li>
            </ul>
          </ParentProgress>
          <ParentProgress header="Bachelor in Professional Accounting">
            <ul>
              <li className="mb-2">
                <Link
                  to="/admission/bpa_ppa"
                  className="hover:underline hover:text-main "
                >
                  - PPA Business School (France)
                </Link>
              </li>
            </ul>
          </ParentProgress>
          <ParentProgress header="Bachelor of Art in Creative Design">
            <ul>
              <li className="">
                <Link
                  to="/admission/creative_ican"
                  className="hover:underline hover:text-main "
                >
                  - Institut de Création et d'Animation Numériques (ICAN,France)
                </Link>
              </li>
            </ul>
          </ParentProgress>
          <ParentProgress header="BSc in Computer Science/ Artificial Intelligence">
            <ul>
              <li className="">
                <Link
                  to="/admission/cs_esgi"
                  className="hover:underline hover:text-main "
                >
                  - École Supérieure de Génie Informatique (ESGI,France)
                </Link>
              </li>
            </ul>
          </ParentProgress>
          <ParentProgress header="Diplomas and Professional Certificate Courses">
            <ul>
              <li className="">
                <Link
                  to="/admission/iqn"
                  className="hover:underline hover:text-main "
                >
                  - International Qualifications Network (IQN)
                </Link>
              </li>
            </ul>
          </ParentProgress>
        </div>
      </div>
    </div>
  );
}

export default Programs;
