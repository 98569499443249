import React, { useEffect } from "react";

import ParentProgress from "../../components/Accordion";

function Diploma() {
  useEffect(() => {
    document.title = "NIC | Admission";
  }, []);
  return (
    <>
      <head>
        <base href="/" />
      </head>
      <div className="flex flex-col gap-2">
        <div className="w-full bg-gray-300 px-8 py-10">
          <div className="flex flex-row justify-between align-middle items-center">
            <div className="text-lg font-bold">Course Overview</div>
            <img
              className="object-fill md:h-20 h-16"
              src="./assets/Partners/IQN-1.png"
              alt="ign"
            />
          </div>
          <div className="md:text-base text-sm mt-10">
            Newton International College offers various kinds of Professional
            Certificates and Diploma Courses.
          </div>
          <div className="md:ml-3 m-0 mt-10 flex flex-row justify-around">
            <div className="flex flex-row gap-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10 my-auto text-main"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                  clipRule="evenodd"
                />
              </svg>
              <div className="flex flex-col">
                <p className="text-main font-bold"> Start Dates </p>
                <p className="text-sm font-bold">March, July, September</p>
              </div>
            </div>
            <div className="flex flex-row gap-3">
              <svg
                className="text-main w-10 h-10 my-auto"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path
                  fill="currentColor"
                  d="M360 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24 0 90.965 51.016 167.734 120.842 192C75.016 280.266 24 357.035 24 448c-13.255 0-24 10.745-24 24v16c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24 0-90.965-51.016-167.734-120.842-192C308.984 231.734 360 154.965 360 64c13.255 0 24-10.745 24-24V24c0-13.255-10.745-24-24-24zm-64 448H88c0-77.458 46.204-144 104-144 57.786 0 104 66.517 104 144z"
                ></path>
              </svg>
              <div className="flex flex-col">
                <p className="text-main font-bold"> Duration </p>
                <p className="text-sm font-bold"> 4 months </p>
              </div>
            </div>
          </div>
        </div>
        <ParentProgress header="Diploma Courses">
          <div className="px-5 ">
            <ul className=" md:text-base text-sm list-disc">
              <li>Diploma in Human Resource Management</li>
              <li>Diploma in Marketing</li>
              <li>Diploma in Supply Chain Management</li>
            </ul>
          </div>
        </ParentProgress>
        <ParentProgress header="Professional Certificate">
          <div className="px-5 ">
            <ul className=" md:text-base text-sm list-disc">
              <li>Certified Finance Specialist</li>
              <li>Certified Project Management Analyst</li>
              <li>Certified Project Manager</li>
            </ul>
          </div>
        </ParentProgress>
        <ParentProgress header="Tuition Fees">
          <div className="md:p-5 p-0">
            <div className="flex flex-row justify-between">
              <div className="text-center p-2 ">Total</div>
              <div className="text-center p-2 ">
                ~ 1500USD (Fees are subject to change without prior notice)
              </div>
            </div>
          </div>
        </ParentProgress>
        <ParentProgress header="How To Apply">
          <div className="px-5">
            Download application form
            <a className="text-blue-500 cursor-pointer hover:text-main">
              &nbsp;here.&nbsp;
            </a>
            Contact Newton Internation College
          </div>
        </ParentProgress>
      </div>
    </>
  );
}

export default Diploma;
