import MDEditor from "@uiw/react-md-editor";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";

export default function SingleNews() {
  const [news, setNews] = useState({});
  const { newsId } = useParams();
  useEffect(() => {
    document.title = "NIC | News";
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/news/${newsId}`
      );
      setNews(data);
    };
    fetchData().catch((err) => {
      console.log(err.response.data.error);
      setNews("error");
    });
  }, [newsId]);

  if (news === {}) {
    return <Loader />;
  } else if (news === "error") {
    return (
      <div className="container py-10 h-screen grid items-center bg-gray-200 mx-auto">
        <div className="text-center  my-32 flex flex-col gap-5 text-4xl">
          No Information for this news id.
        </div>
      </div>
    );
  } else {
    return (
      <>
        <base href="/" />

        <div className="container py-10 bg-gray-200 mx-auto">
          <div className="md:max-w-6xl max-w-sm mx-auto mb-10 mt-32 flex flex-col gap-5 px-3">
            <div className="text-center font-bold text-3xl">"{news.title}"</div>
            <span className="text-center font-light text-sm">
              Published on {moment(news.date).format("MMMM Do YYYY, hh:mm")}
            </span>
            <span className="rounded-lg w-32 mx-auto text-center bg-gray-400  text-xs font-semibold p-3 ">
              {news.type}
            </span>
            <div>
              <img
                className="object-contain h-96 mx-auto"
                src={news.imageUrl}
                alt="classroom"
              />
            </div>
            <div className="  text-base ">
              <MDEditor.Markdown
                style={{ fontFamily: "Open Sans, sans-serif" }}
                source={news.description}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
