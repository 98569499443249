import axios from "axios";
import React, { useEffect, useState } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Loader from "../components/Loader";

// const images = [
//   {
//     url: "./assets/Yangon Campus/1.jpg",
//     title: "Yangon Campus",
//   },
//   {
//     url: "./assets/Yangon Campus/4.jpg",
//     title: "Yangon Campus",
//   },
//   {
//     url: "./assets/Yangon Campus/5.jpg",
//     title: "Yangon Campus",
//   },
//   {
//     url: "./assets/nic-npw2.jpg",
//     title: "Naypyitaw Campus",
//   },
//   {
//     url: "./assets/nic-npw1.jpg",
//     title: "Naypyitaw Campus",
//   },
// ];

const About = () => {
  const [images, setImages] = useState();

  useEffect(() => {
    document.title = "NIC | About";
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/campus`
      );
      setImages(data);
    };
    fetchData().catch((err) => console.log(err));
  }, [images]);
  const [activeSlide, setActiveSlide] = useState(1);
  // setInterval(function () {
  //   setActiveSlide(activeSlide === "Yangon" ? "Naypyitaw" : "Yangon");
  // }, 3000);

  useEffect(() => {
    const timer = setInterval(async () => {
      if (activeSlide + 1 > 5) {
        setActiveSlide(1);
      } else {
        setActiveSlide(activeSlide + 1);
      }
    }, 3000);
    return () => {
      window.clearInterval(timer);
    };
  }, [activeSlide]);
  if (images === undefined || images.length === 0) {
    return (
      <>
        <Loader />
      </>
    );
  }
  return (
    <>
      <div className="container mb-12 text-justify">
        The Newton International college was founded in November 2019. Our aim
        is to provide Diploma, Bachelor Degree and Master Degree in the context
        of Business, Graphic Design and Computer Science. In 2021, the Naypyitaw
        Campus of Newton International College was opened. Newton International
        College provides excellent international programs, internships,
        industrial workshops and academic exchange to our valued students. NIC
        focuses on providing each student with individualised attention in their
        academic pursuits as well as well-rounded experience in and out of the
        classroom.
      </div>
      <div className="container relative w-full mx-auto md:h-96 h-64 overflow-hidden">
        <div className="slide-container h-96 md:w-3/4 w-full">
          <Slide duration={3000} arrows={false}>
            {images.map((image, index) => (
              <div key={index} className="relative each-slide h-96 w-full">
                <div className="absolute bg-gray-800 opacity-80 px-5 py-3 z-50 w-full text-center bottom-32 md:bottom-0 h-1/4 md:h-1/3 grid items-center text-white text-2xl">
                  {image.title}
                </div>
                <img
                  className="object-fill h-96 w-full"
                  src={image.url}
                  alt="Yangon Campus"
                />
              </div>
            ))}
          </Slide>
        </div>
      </div>
    </>
  );
};

export default About;
