import { Link } from "react-router-dom";
import { useState } from "react";
import moment from "moment";

const NewsCard = ({ id, type, title, date, imageUrl }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Link to={`/news/${id}`}>
      <div
        className={`max-w-sm rounded-sm overflow-hidden mx-auto shadow-lg bg-gray-300  transform duration-200 hover:cursor-pointer ${
          hovered ? "-translate-y-3" : "translate-y-0"
        } `}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <img className="w-full" src={imageUrl} alt="Sunset in the mountains" />
        <div className="px-6 py-4">
          <div className=" text-xl mb-2">{title}</div>
          <span className="inline-block rounded-full bg-gray-400 px-3 py-2 text-xs font-semibold  mr-2 mb-2">
            {type}
          </span>
        </div>
        <div className="pb-2 flex flex-row justify-between px-6 ">
          <span className=" rounded-full  py-1  font-light ">
            {" "}
            {moment(date).fromNow()}
          </span>
        </div>
      </div>
    </Link>
  );
};

export default NewsCard;
