import React, { useEffect } from "react";

import ParentProgress from "../../components/Accordion";

function MbaUCAM() {
  useEffect(() => {
    document.title = "NIC | Admission";
  }, []);
  return (
    <>
      <head>
        <base href="/" />
      </head>
      <>
        <div className="flex flex-col gap-2">
          <div className="w-full bg-gray-300 px-8 py-10">
            <div className="flex flex-row justify-between align-middle items-center">
              <div className="text-lg font-bold">Course Overview</div>
              <img
                className="object-fill h-20"
                src="./assets/Partners/logo_ucam_blanco_v.png"
                alt="ucam"
              />
            </div>
            <div className="md:text-base text-sm mt-10">
              Newton International College’s MBA from UCAM University was
              particularly designed for people with a desire to advance their
              careers in the area of business, management, and leadership. This
              MBA program is aimed at international university graduates from a
              variety of academic backgrounds such as law, communication,
              engineering, health or nutrition, arts and humanities, who wish to
              gain advanced knowledge in the field of business administration,
              assessment and consulting as well as general management, strategy
              formation and capacity planning.
            </div>
            <div className="md:ml-3 m-0 mt-10 flex flex-row justify-around">
              <div className="flex flex-row gap-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-10 w-10 my-auto text-main"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                    clipRule="evenodd"
                  />
                </svg>
                <div className="flex flex-col">
                  <p className="text-main font-bold"> Start Dates </p>
                  <p className="text-sm font-bold">March, July, September</p>
                </div>
              </div>
              <div className="flex flex-row gap-3">
                <svg
                  className="text-main w-10 h-10 my-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path
                    fill="currentColor"
                    d="M360 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24 0 90.965 51.016 167.734 120.842 192C75.016 280.266 24 357.035 24 448c-13.255 0-24 10.745-24 24v16c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24 0-90.965-51.016-167.734-120.842-192C308.984 231.734 360 154.965 360 64c13.255 0 24-10.745 24-24V24c0-13.255-10.745-24-24-24zm-64 448H88c0-77.458 46.204-144 104-144 57.786 0 104 66.517 104 144z"
                  ></path>
                </svg>
                <div className="flex flex-col">
                  <p className="text-main font-bold"> Duration </p>
                  <p className="text-sm font-bold"> 12-15 months </p>
                </div>
              </div>
            </div>
          </div>
          <ParentProgress header="Subjects">
            <div className="md:p-5 p-0">
              <div className="font-semibold text-lg">Course Module</div>
              <ul className="mt-5 md:text-base text-xs list-disc md:pl-10 p-2">
                <li>Strategic Human Resource Management</li>
                <li>Ethics, Responsibility and Sustainability</li>
                <li>Strategic Marketing Management</li>
                <li>Strategic Financial Management</li>
                <li>Corporate Strategy</li>
                <li>Strategic Operations Management</li>
                <li>Organisation Management and Strategy</li>
                <li>Research Methods</li>
                <li>Master Thesis</li>
              </ul>
            </div>
          </ParentProgress>
          <ParentProgress header="Eligibility & Requirements">
            <div className="md:p-5 p-0">
              <div className="font-semibold text-lg">
                Admission Requirements
              </div>
              <ul className="mt-5 md:text-base text-sm list-disc md:pl-10 p-2">
                <li>Bachelor’s Degree.</li>
                <li>
                  For Non-Degree Holders, (2) or (3) years Diploma with Minimum
                  (2) years of work experience in Management Position (or)
                  Business Owner.
                </li>
                <li>
                  Two letters of recommendation or completed ‘Reference Forms’
                  must be attached with the completed application form. (One
                  letter from Academic and one letter from the work).
                </li>
              </ul>
              <div className="font-semibold text-lg mt-5">
                Documentations for application
              </div>
              <ul className="mt-5 md:text-base text-sm list-disc md:pl-10 p-2">
                <li>A completed Application Form.</li>
                <li>
                  Certified true copies of the Bachelor’s Degree (or) Diploma
                </li>
                <li>
                  Transcript of Academic Record (For Bachelor Degree (or)
                  Diploma)
                </li>
                <li>2 Passport Photos</li>
                <li>Photocopy of NRC Card </li>
                <li>
                  Two (2) copies of Reference Forms to be filled in by the
                  academic (Minimum Lecturer Level) and immediate superior at
                  the workplace.
                </li>
              </ul>
            </div>
          </ParentProgress>
          <ParentProgress header="Career Track">
            <div className="">
              <ul className="md:text-base text-sm list-disc md:pl-10 p-2">
                <li>Business Consultant</li>
                <li>Business Development Manager</li>
                <li>General Manager</li>
                <li>Industry Specialist Manager</li>
                <li>Operation Manager</li>
              </ul>
            </div>
          </ParentProgress>
          <ParentProgress header="Tuition Fees">
            <div className="md:p-5 p-0">
              <div className="flex flex-row justify-between">
                <div className="text-center p-2 ">Total</div>
                <div className="text-center p-2 ">
                  ~ 7000USD (Fees are subject to change without prior notice)
                </div>
              </div>
            </div>
          </ParentProgress>
          <ParentProgress header="How To Apply">
            <div className="px-5">
              Download application form
              <a className="text-blue-500 cursor-pointer hover:text-main">
                &nbsp;here.&nbsp;
              </a>
              Contact Newton Internation College
            </div>
          </ParentProgress>
        </div>
      </>
    </>
  );
}

export default MbaUCAM;
