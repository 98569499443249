import { Link } from "react-router-dom";
import { useState } from "react";

const MenuBar = () => {
  const [showCampus, setShowCampus] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <nav className="z-50 fixed w-full ">
      <div className="bg-slate-900">
        <div className="flex flex-row lg:justify-around justify-between p-3 font-nav text-white md:w-5/6 mx-auto">
          <div className="">
            {/* <p className="font-bold text-3xl">NIC</p> */}
            <Link to="/">
              <img
                className="md:h-20 h-16"
                src="./assets/NIC-2.png"
                alt="LOGO"
              />
            </Link>
          </div>
          {/* desktop menu */}
          <ul
            id="menu-items"
            className=" lg:flex hidden flex-1  flex-row justify-evenly  items-center"
          >
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/news">News</Link>
            </li>
            <li>
              <Link to="/programs">Programs</Link>
            </li>
            <li onMouseEnter={() => setShowCampus(true)}>Admissions</li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>

          {/* humburger menu */}
          <div
            className="items-center float-right hover:cursor-pointer lg:hidden  flex-col flex gap-2 my-auto"
            onClick={() => setOpenMenu(!openMenu)}
          >
            <div
              className={`w-8 h-1 bg-white rounded-xl transform duration-500 origin-center ease-in-out ${
                openMenu ? ` translate-y-3 rotate-45` : `rotate-0 translate-y-0`
              }`}
            ></div>
            <div
              className={`w-8 h-1 bg-white rounded-xl transform duration-500 ease-in-out ${
                openMenu
                  ? `opacity-0 -translate-x-5`
                  : `opacity-100 translate-x-0`
              }`}
            ></div>
            <div
              className={`w-8 h-1 bg-white rounded-xl transform duration-500 ease-in-out  ${
                openMenu
                  ? `-translate-y-3 -rotate-45`
                  : `rotate-0 translate-y-0`
              }`}
            ></div>
          </div>
          {/*  */}
        </div>
      </div>
      <div className="bg-main h-4 w-full shadow-lg"></div>

      {/* mobile-menu */}
      <div
        className={`transition-all overflow-hidden duration-500 origin-top shadow-none lg:hidden block ease-in-out ${
          openMenu ? "max-h-screen" : "max-h-0"
        }  bg-slate-900 opacity-95  text-white px-3 `}
      >
        <ul className="max-w-2xl mx-auto text-lg ">
          <li className="py-5  border-b-gray-700 border-b-2">
            <p
              className="transform duration-500  hover:text-main  hover:cursor-pointer text-center"
              onClick={() => setOpenMenu(!openMenu)}
            >
              <Link to="/">Home</Link>
            </p>
          </li>
          <li className="py-5  border-b-gray-700 border-b-2">
            <p
              className="transform duration-500  hover:text-main  hover:cursor-pointer text-center"
              onClick={() => setOpenMenu(!openMenu)}
            >
              <Link to="/about">About Us</Link>
            </p>
          </li>
          <li className="py-5  border-b-gray-700 border-b-2">
            <p
              className="transform duration-500  hover:text-main  hover:cursor-pointer text-center"
              onClick={() => setOpenMenu(!openMenu)}
            >
              <Link to="/news">News</Link>
            </p>
          </li>
          <li className="py-5  border-b-gray-700 border-b-2">
            <p
              className="transform duration-500  hover:text-main hover:cursor-pointer text-center"
              onClick={() => setOpenMenu(!openMenu)}
            >
              <Link to="/programs">Programs</Link>
            </p>
          </li>

          <li className="py-5  border-b-gray-700 border-b-2">
            <p
              className="transform duration-500  hover:text-main  hover:cursor-pointer text-center"
              onClick={() => setShowCampus(!showCampus)}
            >
              Admissions
            </p>
            <ul className={`${showCampus ? `block` : `hidden`} text-gray-600 `}>
              <li className="py-2 ">
                <p
                  className="transform duration-500 font-light hover:text-main  hover:cursor-pointer text-center"
                  onClick={() => setOpenMenu(!openMenu)}
                >
                  <Link to="/admission/mba_ucam">
                    Master of Business Administration (UCAM)
                  </Link>
                </p>
              </li>
              <li className="py-2">
                <p
                  className="transform duration-500 font-light hover:text-main  hover:cursor-pointer text-center"
                  onClick={() => setOpenMenu(!openMenu)}
                >
                  <Link to="/admission/mba_cu">
                    Master of Business Administration (CU)
                  </Link>
                </p>
              </li>
              <li className="py-2">
                <p
                  className="transform duration-500 font-light hover:text-main  hover:cursor-pointer text-center"
                  onClick={() => setOpenMenu(!openMenu)}
                >
                  <Link to="/admission/mba_egs">
                    Master of Business Administration (EGS)
                  </Link>
                </p>
              </li>
            </ul>
          </li>
          <li className="py-5  ">
            <p
              className="transform duration-500  hover:text-main  hover:cursor-pointer text-center"
              onClick={() => setOpenMenu(!openMenu)}
            >
              <Link to="/contact">Contact Us</Link>
            </p>
          </li>
        </ul>
      </div>

      <div
        className={`transform duration-500 origin-top shadow-none md:block hidden ${
          showCampus ? "scale-y-100 h-auto" : "scale-y-0 h-0"
        }  bg-slate-900 opacity-95 text-white px-3 `}
        onMouseLeave={() => setShowCampus(false)}
      >
        <ul className="max-w-6xl mx-auto text-lg text-gray-300">
          <li className="py-5 ml-5 border-gray-700 border-b-2">
            <p className="transform duration-500  hover:text-main  hover:cursor-pointer">
              <Link to="/admission/mba_ucam">
                Master of Business Administration (UCAM)
              </Link>
            </p>
          </li>
          <li className="py-5 ml-5 border-gray-700 border-b-2 ">
            <p className="transform duration-500  hover:text-main hover:cursor-pointer">
              <Link to="/admission/mba_cu">
                Master of Business Administration (CU)
              </Link>
            </p>
          </li>{" "}
          <li className="py-5 ml-5 ">
            <p className="transform duration-500  hover:text-main  hover:cursor-pointer">
              <Link to="/admission/mba_egs">
                Master of Business Administration (EGS)
              </Link>
            </p>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default MenuBar;
