import React, { useEffect } from "react";

import ParentProgress from "../../components/Accordion";

function BbaEGS() {
  useEffect(() => {
    document.title = "NIC | Admission";
  }, []);
  return (
    <>
      <head>
        <base href="/" />
      </head>
      <>
        <div className="flex flex-col gap-2">
          <div className="w-full bg-gray-300 px-8 py-10">
            <div className="flex flex-row justify-between align-middle items-center">
              <div className="text-lg font-bold">Course Overview</div>
              <img
                className="object-fill h-20"
                src="./assets/Partners/egs.png"
                alt="egs"
              />
            </div>
            <div className="md:text-base text-sm mt-10">
              Students can apply Bachelor of Business Administration (BBA)
              degree of European Global School to build on the abilities they
              learned in their higher education. In the BBA, students enhance
              their knowledge by taking courses that emphasize critical and
              strategic thinking. This is accomplished through a set of
              fundamental business and general education courses at the upper
              level.
              <br />
              <br /> With the business world continually changing and getting
              more complex, the BBA produces well-rounded graduates who can look
              at a company&#39;s problems from various angles.
            </div>
            <div className="md:ml-3 ml-0 mt-10 flex flex-row justify-around">
              <div className="flex flex-row gap-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-10 w-10 my-auto text-main"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                    clipRule="evenodd"
                  />
                </svg>
                <div className="flex flex-col">
                  <p className="text-main font-bold"> Start Dates </p>
                  <p className="text-sm font-bold">March, July, September</p>
                </div>
              </div>
              <div className="flex flex-row gap-3">
                <svg
                  className="text-main w-10 h-10 my-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path
                    fill="currentColor"
                    d="M360 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24 0 90.965 51.016 167.734 120.842 192C75.016 280.266 24 357.035 24 448c-13.255 0-24 10.745-24 24v16c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24 0-90.965-51.016-167.734-120.842-192C308.984 231.734 360 154.965 360 64c13.255 0 24-10.745 24-24V24c0-13.255-10.745-24-24-24zm-64 448H88c0-77.458 46.204-144 104-144 57.786 0 104 66.517 104 144z"
                  ></path>
                </svg>
                <div className="flex flex-col">
                  <p className="text-main font-bold"> Duration </p>
                  <p className="text-sm font-bold"> 3 years </p>
                </div>
              </div>
            </div>
          </div>
          <ParentProgress header="Subjects">
            <div className="md:p-5 p-0">
              <div className="font-semibold text-lg">Course Modules</div>
              <div className="grid grid-cols-2 gap-3 items-start mt-5">
                <div className="md:pl-10 p-0">
                  <p className="font-bold md:text-base text-sm">
                    Year 1 (Semester 1)
                  </p>
                  <ul className="mt-5 md:text-base text-xs list-disc md:p-0 pl-2">
                    <li>College Writing Skills</li>
                    <li>Computer Fundamentals</li>
                    <li>Business Maths</li>
                    <li>Business Communication</li>
                  </ul>
                </div>
                <div className="md:pl-10 p-0">
                  <p className="font-bold md:text-base text-sm">
                    Year 1 (Semester 2)
                  </p>
                  <ul className="mt-5 md:text-base text-xs list-disc ">
                    <li>Management</li>
                    <li>Economics for Business</li>
                    <li>Academic & Research Skills</li>
                    <li>Business Statistics</li>
                  </ul>
                </div>
                <div className="mt-5 md:pl-10 p-0">
                  <p className="font-bold md:text-base text-sm">
                    Year 2 (Semester 1)
                  </p>
                  <ul className="mt-5 md:text-base text-xs list-disc md:p-0 pl-2 ">
                    <li>Marketing</li>
                    <li>Human Resource Management</li>
                    <li>Accounting</li>
                    <li>Consumer Behaviors</li>
                  </ul>
                </div>
                <div className="mt-5 md:pl-10 p-0">
                  <p className="font-bold md:text-base text-sm">
                    Year 2 (Semester 2)
                  </p>
                  <ul className="mt-5 md:text-base text-xs list-disc ">
                    <li>Organizational Behaviors</li>
                    <li>Business Law</li>
                    <li>Global Marketing</li>
                    <li>Business Ethics</li>
                  </ul>
                </div>
                <div className="md:pl-10 p-0">
                  <p className="font-bold md:text-base text-sm">
                    Year 3 (Semester 1)
                  </p>
                  <ul className="mt-5 md:text-base text-xs list-disc md:p-0 pl-2 ">
                    <li>E-commerce</li>
                    <li>Cross-Cultural Management</li>
                    <li>Entrepreneurship</li>
                    <li>Operations Management</li>
                  </ul>
                </div>
                <div className="md:pl-10 p-0">
                  <p className="font-bold md:text-base text-sm">
                    Year 3 (Semester 2)
                  </p>
                  <ul className="mt-5 md:text-base text-xs list-disc ">
                    <li>Current Issues in Management</li>
                    <li>Strategic Management</li>
                    <li>Research Methodology</li>
                    <li>BBA Project</li>
                  </ul>
                </div>
              </div>
            </div>
          </ParentProgress>
          <ParentProgress header="Eligibility & Requirements">
            <div className="md:p-5 p-0">
              <div className="font-semibold text-lg">
                Admission Requirements
              </div>
              <ul className="mt-5 md:text-base text-sm list-disc md:pl-10 p-2">
                <li>IGCSE/GCE 'A' Levels (or)</li>
                <li>High School Graduates </li>
              </ul>
              <div className="font-semibold text-lg mt-5">
                Documentations for application
              </div>
              <ul className="mt-5 md:text-base text-sm list-disc md:pl-10 p-2">
                <li>A completed Application Form.</li>
                <li>Transcript of Academic Record</li>
                <li>2 Passport Photos</li>
                <li>Photocopy of NRC Card </li>
                <li>
                  Two (2) copies of Reference Forms to be filled in by the
                  academic (Minimum Lecturer Level) and immediate superior at
                  the workplace.
                </li>
              </ul>
            </div>
          </ParentProgress>
          <ParentProgress header="Career Track">
            <div className="">
              <ul className="md:text-base text-sm list-disc md:pl-10 p-2">
                <li>Sales Manager</li>
                <li>Analyst</li>
                <li>Project Manager</li>
                <li>Business Development Executive</li>
                <li>Sales and Marketing Executive</li>
              </ul>
            </div>
          </ParentProgress>
          <ParentProgress header="Tuition Fees">
            <div className="md:p-5 p-0">
              <div className="flex flex-row justify-between">
                <div className="text-center p-2 ">Total</div>
                <div className="text-center p-2 ">
                  ~ 8580USD (Fees are subject to change without prior notice)
                </div>
              </div>
            </div>
          </ParentProgress>
          <ParentProgress header="How To Apply">
            <div className="px-5">
              Download application form
              <a
                download
                href=".\assets\Cetificates\EGS - BBA Application Form.pdf"
                className="text-blue-500 cursor-pointer hover:text-main"
              >
                &nbsp;here.&nbsp;
              </a>
              Contact Newton Internation College
            </div>
          </ParentProgress>
        </div>
      </>
    </>
  );
}

export default BbaEGS;
