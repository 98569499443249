import { useEffect, useState } from "react";
import Heading from "../components/Heading";
import { Link } from "react-router-dom";
import NewsCard from "../components/NewsCard";
import axios from "axios";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const HomePage = ({ allnews }) => {
  useEffect(() => {
    document.title = "NIC | Home";
  }, []);
  let news = [];
  let max;
  if (allnews.length < 3) {
    max = allnews.length;
  } else {
    max = 3;
  }
  for (let i = 0; i < max; i++) {
    news.push(allnews[i]);
  }

  if (news) {
    return (
      <>
        <Banner />
        <BackgroundStory />
        <GridItems />
        <Numbers />
        <News allnews={news} />
      </>
    );
  }
};

const Banner = () => {
  const [banner, setBanner] = useState("");
  const [mediaType, setMediaType] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/banner`
      );
      setBanner(data);
      const url = data.mediaUrl.split(".");
      setMediaType(url[url.length - 1]);
    };
    fetchData().catch((err) => console.log(err));
  }, []);
  // useEffect(() => {
  //   const url = banner.mediaUrl.split(".");
  //   setMediaType(url[url.length - 1]);
  // }, [banner]);
  return (
    <>
      <header className="relative -z-50 bg-black lg:top-24 top-16 w-full flex items-center justify-start h-96 lg:h-screen overflow-hidden">
        {mediaType === "mp4" ? (
          <video
            autoPlay
            loop
            muted
            className="lg:top-28 top-14 fixed -z-50 w-full h-96 lg:h-auto lg:min-w-screen lg:min-h-full"
          >
            <source src={banner.mediaUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img
            className="lg:top-28 top-14 fixed -z-50 w-full h-96 lg:h-auto lg:min-w-screen lg:min-h-full"
            alt="classroom"
            src={banner.mediaUrl}
          />
        )}

        <div className="w-full fixed px-5 -z-30 lg:text-5xl md:text-3xl text-xl text-white m-auto text-center break-words">
          {banner.text}
        </div>
      </header>
    </>
  );
};

const GridItems = () => {
  return (
    <div className="w-full bg-gray-800 text-white md:p-12 p-8 pb-32 pt-32">
      <div className="max-w-full">
        <div className="max-w-6xl m-auto mb-10 ">
          <Heading title="COURSES" />
          <p
            className="mb-4 text-justify"
            style={{ textJustify: "inter-word" }}
          >
            Master of Business Administration courses are NIC's best provision.
            We also offer Single Subject Diploma that awarded by IQN (UK) such
            as Diploma in Human Resource Management, Diploma in Hospitality
            Management, Diploma in Marketing, Diploma in Business English,
            Certified Strategic Manager. <br />
            NIC also offers BBA (Bachelor of Business Administration) and BBA in
            PMP (Project Management Professional ) with pathway of Business
            Foundation Program. Bachelor programs which are offered by NIC are
            Bachelor of Business Administration, BA in Creative Design, Bachelor
            in Professional Accounting and BSc in Computer Science. Corporate
            Trainings and other certificate courses such as Certificate in Small
            Business Management, Certificate in General Business Management,
            Certificate in Digital Marketing which can provide the students’
            education the best.
          </p>
        </div>
        <div className="max-w-6xl m-auto grid grid-cols-2 md:flex md:flex-row w-full justify-evenly h-96 gap-5">
          <Card
            title="Master of Business Administration"
            imageUrl="https://images.unsplash.com/photo-1554224154-22dec7ec8818?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
            header="MBA"
          />

          <Card
            title="Bachelor of Business Administration"
            imageUrl="https://media.istockphoto.com/photos/team-of-business-partners-doing-paperwork-while-sitting-at-table-picture-id961552954?k=20&m=961552954&s=612x612&w=0&h=onyXxmoBBSk6tiJ5Bt3lm8vmjBwJVLAfxu6gpsn8dNM="
            header="business"
          />

          <Card
            title="BA in Creative Design"
            imageUrl="https://images.unsplash.com/photo-1611532736597-de2d4265fba3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
            header="Creative Design"
          />
          <Card
            title="BSc in Computer Science and A.I"
            imageUrl="https://images.unsplash.com/photo-1599837565318-67429bde7162?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
            header="Accounting"
          />
        </div>
      </div>
    </div>
  );
};

const Card = (props) => {
  const [hovered, setHovered] = useState(false);
  return (
    <div
      className="flex-1 rounded-xl bg-black relative overflow-hidden z-40"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        className={`absolute w-full h-full bottom-0 z-20 bg-main flex transition duration-200 ease-in-out ${
          hovered ? "opacity-70 " : "opacity-20 "
        }`}
      ></div>
      <div className="absolute w-full h-full z-20 flex justify-start items-end px-2 md:px-4">
        <h3
          className={`text-white font-bold text-base sm:text-lg md:text-2xl flex transition duration-200 ease-in-out mb-1 md:mb-5`}
        >
          {props.title}
        </h3>
      </div>

      <img
        className={`object-fill w-full h-full transform duration-200  ${
          hovered ? "scale-110" : "scale-100"
        } `}
        src={props.imageUrl}
        alt={props.header}
      />
    </div>
  );
};

const Numbers = () => {
  const [focus, setFocus] = useState(false);
  return (
    <VisibilitySensor
      onChange={(isVisible) => {
        if (isVisible) setFocus(true);
      }}
    >
      <div className="w-full bg-main py-10 shadow-lg">
        <div className="flex flex-row justify-evenly text-white">
          <div className="flex flex-col justify-between items-center gap-2">
            <div className="md:text-4xl text-2xl font-bold">
              <CountUp
                start={focus ? 0 : null}
                end={2}
                duration={2}
                redraw={true}
                useEasing={true}
              />
            </div>
            <div className="h-0.5 w-5 bg-black"></div>
            <div className="md:text-2xl sm:text-lg text-sm font-semibold">
              CAMPUSES
            </div>
          </div>

          <div className="flex flex-col justify-between items-center gap-2">
            <div className="md:text-4xl text-2xl font-bold">
              <CountUp
                start={focus ? 0 : null}
                end={500}
                duration={2}
                redraw={true}
                useEasing={true}
                suffix="+"
              />
            </div>
            <div className="h-0.5 w-5 bg-black"></div>
            <div className="md:text-2xl sm:text-lg text-sm font-semibold">
              GRADUATES
            </div>
          </div>
          <div className="flex flex-col justify-between items-center gap-2">
            <div className="md:text-4xl text-2xl font-bold">
              <CountUp
                start={focus ? 0 : null}
                end={7}
                duration={2}
                redraw={true}
                useEasing={true}
                suffix="+"
              />
            </div>
            <div className="h-0.5 w-5 bg-black"></div>
            <div className="md:text-2xl sm:text-lg text-sm font-semibold">
              DEGREES OFFERED
            </div>
          </div>
        </div>
      </div>
    </VisibilitySensor>
  );
};

const BackgroundStory = () => {
  return (
    <div className="w-full bg-slate-200 md:p-12 p-8 pb-32 pt-32">
      <div className="max-w-6xl m-auto md:flex md:flex-row gap-10">
        <div className="md:flex-1 flex flex-col justify-evenly text-center">
          <Heading title="NEWTON INTERNATIONAL COLLEGE" smallHeading />
          <p className="mb-4 text-justify">
            The Newton International college was founded in November 2019.Our
            aim is to provide Diploma, Bachelor Degree and Master Degree in the
            context of Business, Graphic Design and Computer Science. In 2021,
            the Naypyitaw Campus of Newton International College was opened.
          </p>
          <Link to="/about">
            <div className="px-5 py-3 bg-main text-white inline-block rounded-lg hover:cursor-pointer hover:opacity-70 transform duration-200">
              Read More
            </div>
          </Link>
        </div>
        <div className="md:flex-1 rounded-md md:mt-0 mt-10">
          <img
            className="object-fill w-full h-full "
            src="./assets/class1.jpg"
            alt="college campus"
          />
        </div>
      </div>
    </div>
  );
};

const News = ({ allnews }) => {
  return (
    <div className="w-full bg-slate-200 md:p-12 p-8 pb-32 pt-32">
      <div className="max-w-6xl m-auto">
        <Heading title="NEWS" />
        <div className=" m-auto mt-10 md:mt-auto w-full flex md:flex-row flex-col mx-auto justify-start gap-10">
          {allnews.map((news, index) => (
            <NewsCard
              key={index}
              id={news.id}
              imageUrl={news.imageUrl}
              type={news.type}
              date={news.date}
              title={news.title}
            />
          ))}
        </div>

        <div className="text-center">
          {" "}
          <Link to="/news">
            <div className="hover:cursor-pointer hover:bg-main hover:text-white  mt-10 inline-block  border-2 border-main w- text-main rounded-lg p-4">
              VIEW MORE
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
