const Heading = ({ title, smallHeading }) => {
  return (
    <div
      className={`${
        smallHeading ? `md:text-3xl text-lg` : `md:text-5xl text-2xl`
      } font-bold md:mb-12 mb-5 mx-auto font-header text-center`}
    >
      <h1 className="p-3 md:text-left text-center">{title}</h1>
      <div className="flex flex-row mx-auto w-full items-center gap-5">
        <span className="align-middle h-0.5 flex-1 rounded-md bg-main"></span>
        {/* <span className="rounded-full h-3 w-3 bg-main"></span>
        <span className="align-middle h-0.5 flex-1 rounded-md bg-main"></span> */}
      </div>
    </div>
  );
};
export default Heading;
