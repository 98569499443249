import React, { useEffect } from "react";
import Heading from "../components/Heading";
import NewsCard from "../components/NewsCard";

function News({ allnews }) {
  useEffect(() => {
    document.title = "NIC | News";
  }, []);
  return (
    <div className="container py-10 bg-gray-200 mx-auto">
      <div className="md:max-w-6xl max-w-sm px-3 mx-auto my-32">
        <Heading title="NEWS & ANNOUCEMENTS" />
        <div className="md:grid md:grid-cols-3 m-auto w-full flex flex-col mx-auto justify-evenly gap-10">
          {allnews.map((news, index) => (
            <NewsCard
              key={index}
              id={news.id}
              imageUrl={news.imageUrl}
              type={news.type}
              date={news.date}
              title={news.title}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default News;
