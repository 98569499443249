import React, { useEffect } from "react";
import Heading from "../components/Heading";

function Contact() {
  useEffect(() => {
    document.title = "NIC | Contact";
  }, []);
  return (
    <>
      <div className="flex md:flex-row flex-col justify-around gap-5">
        {/* Yangon Campus */}
        <div className="flex flex-col flex-1 shadow-lg rounded-lg md:p-10 p-5 bg-gray-100">
          <div className="mt-2">
            <Heading title="Yangon Campus" smallHeading />
            <div className="text-gray-600 md:text-base text-sm">
              <div className="flex flex-row mb-5 gap-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 my-auto mx-auto"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                    clipRule="evenodd"
                  />
                </svg>
                <p className="flex-1">
                  {" "}
                  No. 731, 4th Ward, Zarga Street, South Okkalapa Township,
                  Yangon.{" "}
                </p>
              </div>

              <div className="flex flex-row mb-5 gap-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 my-auto"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                </svg>
                <a href="tel:09881204466" className="flex-1">
                  {" "}
                  09 881 20 44 66{" "}
                </a>
              </div>
              <div className="flex flex-row mb-5 gap-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 my-auto"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                </svg>
                <a href="tel:09881204477" className="flex-1">
                  {" "}
                  09 881 20 44 77{" "}
                </a>
              </div>
              <div className="flex flex-row gap-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 my-auto"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z"
                    clipRule="evenodd"
                  />
                </svg>
                <a href="mailto:connect@nicmyanmar.com" className="flex-1">
                  {" "}
                  connect@nicmyanmar.com{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Naypyitaw Campus */}
        <div className="flex flex-col shadow-lg rounded-lg  flex-1 md:p-10 p-5 bg-gray-100">
          <div className="mt-2">
            <Heading title="Naypyitaw Campus" smallHeading />
            <div className="text-gray-600 md:text-base text-sm">
              <div className="flex flex-row mb-5  gap-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 my-auto"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                    clipRule="evenodd"
                  />
                </svg>
                <p className="flex-1">
                  No. Ou-5740, Kyaik Hto Street, Nyarnadipa Ward, Ottarathiri
                  Township, Naypyitaw.{" "}
                </p>
              </div>

              <div className="flex flex-row mt-2 mb-5 gap-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 my-auto"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                </svg>
                <a href="tel:09881204466" className="flex-1">
                  {" "}
                  09 881 20 44 66{" "}
                </a>
              </div>

              <div className="flex flex-row mb-5 gap-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 my-auto"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                </svg>
                <a href="tel:09881204477" className="flex-1">
                  {" "}
                  09 881 20 44 77{" "}
                </a>
              </div>

              <div className="flex flex-row gap-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 my-auto"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z"
                    clipRule="evenodd"
                  />
                </svg>
                <a href="mailto:connect@nicmyanmar.com" className="flex-1">
                  {" "}
                  connect@nicmyanmar.com{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center">
        <a
          className=" mt-16 text-3xl hover:cursor-pointer hover:text-main inline-block"
          href="https://www.facebook.com/NICMyanmar"
          target="_blank"
          rel="noreferrer"
        >
          <i class="fa-brands fa-facebook-square not-italic"></i>&nbsp; Check
          Our Facebook Page
        </a>
      </div>
    </>
  );
}

export default Contact;
