import React, { useEffect, useState } from "react";
import axios from "axios";
import Authentication from "./Authentication";
import moment from "moment";
import MDEditor from "@uiw/react-md-editor";
import Cookie from "universal-cookie";

const cookies = new Cookie();

// const tempData = [
//   {
//     url: "../../assets/Yangon Campus/1.jpg",
//     title: "Yangon Campus",
//     author: "someone",
//   },
//   {
//     url: "../../assets/Yangon Campus/4.jpg",
//     title: "Yangon Campus",
//     author: "someone",
//   },
//   {
//     url: "../../assets/Yangon Campus/5.jpg",
//     title: "Yangon Campus",
//     author: "someone",
//   },
//   {
//     url: "../../assets/nic-npw2.jpg",
//     title: "Naypyitaw Campus",
//     author: "someone",
//   },
//   {
//     url: "../../assets/nic-npw1.jpg",
//     title: "Naypyitaw Campus",
//     author: "someone",
//   },
// ];

function Admin() {
  useEffect(() => {
    document.title = "NIC | Admin";
  }, []);
  const [openModal, setOpenModal] = useState(false);
  const [news, setNews] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [activeTab, setActiveTab] = useState("Banner");
  const [allnews, setAllNews] = useState({});
  const [campusPhotos, setCampusPhotos] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const jwt = cookies.get("jwt");
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/users/currentUser`,
        { jwt: jwt }
      );
      setCurrentUser(data);
    };
    fetchData().catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/news`
      );
      setAllNews(data);
    };
    fetchData().catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/campus`
      );
      setCampusPhotos(data);
    };
    fetchData().catch((err) => console.log(err));
  }, []);
  const logoutHandler = () => {
    if (window.confirm("Are you sure you want to Log out?")) {
      const logout = async () => {
        cookies.remove("jwt");
      };
      logout().catch((err) => console.log(err));
      window.location.reload();
    }
  };
  if (currentUser === "" && !currentUser) {
    return <Authentication setCurrentUser={setCurrentUser} />;
  } else {
    return (
      <div className="my-5">
        <EditModal
          news={news}
          open={openModal}
          setOpen={setOpenModal}
          currentUser={currentUser.name}
        />
        <div className="mx-auto text-center text-3xl my-10">
          Admin: {currentUser.name}
          <span
            className="ml-5 text-lg inline-block bg-main text-white p-2 rounded cursor-pointer"
            onClick={logoutHandler}
          >
            Log out
          </span>{" "}
        </div>
        <div className="rounded border border-gray-400 shadow-lg w-1/2 mx-auto mt-4 bg-white">
          <ul className="inline-flex py-3 px-4 w-full border-black shadow-sm justify-around  border-b">
            <li
              className={`px-4 text-gray-800 font-semibold py-2 -mb-px cursor-pointer ${
                activeTab === "News" ? "border-b-2 border-main" : ""
              } `}
              onClick={() => setActiveTab("News")}
            >
              Add News or Announcement
            </li>
            <li
              className={`px-4 text-gray-800 font-semibold py-2 -mb-px cursor-pointer ${
                activeTab === "Banner" ? "border-b-2 border-main" : ""
              } `}
              onClick={() => setActiveTab("Banner")}
            >
              Change Banner
            </li>
            <li
              className={`px-4 text-gray-800 font-semibold py-2 -mb-px cursor-pointer ${
                activeTab === "All News" ? "border-b-2 border-main" : ""
              } `}
              onClick={() => setActiveTab("All News")}
            >
              All News
            </li>
            <li
              className={`px-4 text-gray-800 font-semibold py-2 -mb-px cursor-pointer ${
                activeTab === "Campus" ? "border-b-2 border-main" : ""
              } `}
              onClick={() => setActiveTab("Campus")}
            >
              Campus Photo
            </li>
          </ul>

          <div>
            <div className="px-6 py-8">
              {activeTab === "News" ? (
                <AddNews currentUser={currentUser.name} />
              ) : activeTab === "Banner" ? (
                <Banner />
              ) : activeTab === "All News" ? (
                <AllNews
                  currentUser={currentUser.name}
                  allnews={allnews}
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  setNews={setNews}
                />
              ) : (
                <CampusPhoto
                  currentUser={currentUser.name}
                  campusPhotos={campusPhotos}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const AddNews = ({ currentUser }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState("");
  const [type, setType] = useState("Announcement");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      formData.append("author", currentUser);
      formData.append("imageUrl", file);
      formData.append("type", type);
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/news`,
        formData
      );
      alert(data.message);
      window.location.reload();
    } catch (err) {
      alert(err.response);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="name"
        >
          Title (Maxlength - 100)
        </label>
        <textarea
          rows={2}
          className="shadow border-black appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="name"
          type="text"
          placeholder="Text input"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          maxLength={100}
          required
        />
      </div>
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="name"
        >
          Content Image
        </label>
        <input
          className="shadow-lg bg-white border-black appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="file"
          placeholder="Text input"
          onChange={(e) => setFile(e.target.files[0])}
          required
        />
      </div>
      <div className="mb-4">
        <label
          className="block  uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
          htmlFor="grid-state"
        >
          Type
        </label>
        <div className="relative">
          <select
            className="block appearance-none w-full bg-white border border-black text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            onChange={(e) => setType(e.target.value)}
            value={type}
          >
            <option value="Announcement">Announcement</option>
            <option value="Admission">Admission</option>
            <option value="Activity">Activity</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Message (Markdown)
          <MDEditor
            className="overflow-hidden shadow border-black form-textarea mt-1 block  border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Textarea"
            value={description}
            onChange={setDescription}
            required
          />
        </label>
      </div>

      <div className="flex items-center justify-between">
        <button
          className="bg-main hover:opacity-90 w-full text-center text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="submit"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

const AllNews = ({ allnews, setOpenModal, setNews }) => {
  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      const deleteNews = async () => {
        const { data } = await axios.delete(
          `${process.env.REACT_APP_BASE_URL}/api/news/${id}`
        );
        alert(data.message);
        window.location.reload();
      };
      try {
        deleteNews();
      } catch (err) {
        console.log(err);
        alert("Some error happened.");
      }
    }
  };
  if (allnews === undefined || allnews.length === 0) {
    return (
      <div className=" mt-5 w-full grid items-center text-center">
        {" "}
        No Posts Yet{" "}
      </div>
    );
  } else {
    return (
      <div>
        <div className=" mt-5 w-full grid grid-cols-2 mx-auto justify-evenly gap-10">
          {allnews.map((news) => (
            <div
              className="max-w-sm overflow-hidden mx-auto shadow-lg bg-gray-300 pb-5 transform duration-200"
              key={news.id}
            >
              <img
                className="w-full"
                src={news.imageUrl}
                alt="Sunset in the mountains"
              />
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">{news.title}</div>
                <span className="inline-block rounded-full bg-gray-400 px-3 py-2 text-xs font-semibold  mr-2 mb-2">
                  {news.type}
                </span>
                <div className="font-light text-sm my-3">
                  {moment(news.date).fromNow()}
                </div>
                <div className="font-bold"> Author: {news.author} </div>
              </div>

              <div className="flex flex-row justify-evenly">
                <div
                  className="ml-5 text-lg w-20 text-center bg-blue-500 text-white p-2 rounded cursor-pointer"
                  onClick={() => {
                    setOpenModal(true);
                    setNews(news);
                  }}
                >
                  Edit
                </div>
                <div
                  className="ml-5 text-lg w-20 text-center bg-red-500 text-white p-2 rounded cursor-pointer"
                  onClick={() => handleDelete(news.id)}
                >
                  Delete
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
};

const EditModal = ({ news, open, setOpen, currentUser }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState();
  const [type, setType] = useState("");

  useEffect(() => {
    setTitle(news.title);
    setDescription(news.description);
    setType(news.type);
  }, [news]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("author", currentUser);
    formData.append("imageUrl", file);
    formData.append("type", type);
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/news/${news.id}`,
        formData
      );
      alert(data.message);
      window.location.reload();
    } catch (err) {
      alert(
        "Some error happened. Image type should be only png,jpg and jpeg and should not exceed 3MB in size."
      );
    }
  };
  if (open && title) {
    return (
      <form
        className={`bg-gray-700 bg-opacity-80 fixed z-10 overflow-y-auto top-0 h-full w-full left-0`}
        onSubmit={handleSubmit}
      >
        <div className="bg-gray-300 w-3/4 mx-auto p-10">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              Title
            </label>
            <input
              className="shadow border-black appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              placeholder="Text input"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              Content Image
            </label>
            <input
              className="shadow-lg bg-white border-black appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="file"
              placeholder="Text input"
              onChange={(e) => setFile(e.target.files[0])}
              required
            />
          </div>
          <div className="mx-auto w-1/3">
            <img
              className="object-fill w-full"
              alt={news.imageName}
              src={news.imageUrl}
            />
          </div>
          <div className="mb-4">
            <label
              className="block  uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-state"
            >
              Type
            </label>
            <div className="relative">
              <select
                className="block appearance-none w-full bg-white border border-black text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                onChange={(e) => setType(e.target.value)}
                value={type}
              >
                <option value="Announcement">Announcement</option>
                <option value="Admission">Admission</option>
                <option value="Activity">Activity</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Message (Markdown)
              <MDEditor
                className="overflow-hidden shadow border-black form-textarea mt-1 block  border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Textarea"
                value={description}
                onChange={setDescription}
                required
              />
            </label>
          </div>

          <div className="flex items-center justify-evenly">
            <button
              className="inline-block bg-main hover:opacity-90  text-center text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Submit
            </button>
            <button
              className="inline-block bg-red-500 hover:opacity-90  text-center text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>{" "}
      </form>
    );
  } else {
    return null;
  }
};

const Banner = () => {
  const [input, setInput] = useState("");
  const [file, setFile] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("text", input);
      formData.append("mediaUrl", file);
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/banner`,
        formData
      );
      alert(data.message);
      window.location.reload();
    } catch (err) {
      alert(err.response);
    }
  };
  return (
    <div>
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="name"
        >
          Text (Optional)
        </label>
        <input
          className="shadow border-black appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="name"
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Text input"
          required
        />
      </div>
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="name"
        >
          Banner Image or Video
        </label>
        <input
          className="shadow-lg bg-white border-black appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="file"
          placeholder="Text input"
          onChange={(e) => setFile(e.target.files[0])}
          required
        />
      </div>
      <div className="flex items-center justify-between">
        <button
          className="bg-main hover:opacity-90 w-full text-center text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

const CampusPhoto = ({ campusPhotos, currentUser }) => {
  const [campusPhotoModalOpen, setCampusPhotoOpen] = useState(false);
  const [campusPhoto, setCampusPhoto] = useState({});
  const [addNewOne, setAddNewOne] = useState(false);

  const EditCampusModal = ({ campusPhoto, currentUser, addNewOne }) => {
    const [title, setTitle] = useState("");
    const [file, setFile] = useState();

    useEffect(() => {
      if (!addNewOne) setTitle(campusPhoto.title);
    }, [campusPhoto, addNewOne]);

    const handleSubmit = async (e) => {
      e.preventDefault();

      const formData = new FormData();
      formData.append("title", title);
      formData.append("author", currentUser);
      formData.append("url", file);
      try {
        //for editing the campus photo
        if (!addNewOne) {
          const { data } = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/campus/${campusPhoto.id}`,
            formData
          );
          alert(data.message);
        }
        //for uploading a new campus photo
        else {
          const { data } = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/campus`,
            formData
          );
          alert(data.message);
        }
        window.location.reload();
      } catch (err) {
        alert(
          "Some error happened. Image type should be only png,jpg and jpeg and should not exceed 3MB in size."
        );
      }
    };
    if (campusPhotoModalOpen && title) {
      return (
        <form
          className={`bg-gray-700 bg-opacity-80 fixed z-10 overflow-y-auto top-0 h-full w-full left-0`}
          onSubmit={handleSubmit}
        >
          <div className="bg-gray-300 w-1/2 mx-auto my-10 p-10">
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="name"
              >
                Title
              </label>
              <input
                className="shadow border-black appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                type="text"
                placeholder="Text input"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="name"
              >
                The Image
              </label>
              <input
                className="shadow-lg bg-white border-black appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="file"
                placeholder="Text input"
                onChange={(e) => setFile(e.target.files[0])}
                required
              />
            </div>
            <div className="mx-auto w-1/3 mb-4">
              <img
                className="object-fill w-full"
                alt={campusPhoto.imageName}
                src={campusPhoto.url}
              />
            </div>
            <div className="flex items-center justify-evenly">
              <button
                className="inline-block bg-main hover:opacity-90  text-center text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Submit
              </button>
              <button
                className="inline-block bg-red-500 hover:opacity-90  text-center text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={() => setCampusPhotoOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>{" "}
        </form>
      );
    } else if (campusPhotoModalOpen) {
      return (
        <form
          className={`bg-gray-700 bg-opacity-80 fixed z-10 overflow-y-auto top-0 h-full w-full left-0`}
          onSubmit={handleSubmit}
        >
          <div className="bg-gray-300 w-1/2 mx-auto my-10 p-10">
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="name"
              >
                Title
              </label>
              <input
                className="shadow border-black appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                type="text"
                placeholder="Text input"
                maxLength={20}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="name"
              >
                The Image
              </label>
              <input
                className="shadow-lg bg-white border-black appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="file"
                placeholder="Text input"
                onChange={(e) => setFile(e.target.files[0])}
                required
              />
            </div>
            <div className="flex items-center justify-evenly">
              <button
                className="inline-block bg-main hover:opacity-90  text-center text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Submit
              </button>
              <button
                className="inline-block bg-red-500 hover:opacity-90  text-center text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={() => setCampusPhotoOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>{" "}
        </form>
      );
    } else {
      return null;
    }
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      const deletePhoto = async () => {
        const { data } = await axios.delete(
          `${process.env.REACT_APP_BASE_URL}/api/campus/${id}`
        );
        alert(data.message);
        window.location.reload();
      };
      try {
        deletePhoto();
      } catch (err) {
        console.log(err);
        alert("Some error happened.");
      }
    }
  };
  if (campusPhotos === undefined || campusPhotos.length === 0) {
    return (
      <div className=" mt-5 w-full grid items-center text-center">
        No campus photos
      </div>
    );
  } else {
    return (
      <div>
        <EditCampusModal
          campusPhoto={campusPhoto}
          currentUser={currentUser}
          addNewOne={addNewOne}
        />
        <div className=" mt-3 w-full grid grid-cols-3 mx-auto justify-evenly gap-5">
          {campusPhotos.map((image) => (
            <div
              className="max-w-md rounded border w-full overflow-hidden flex flex-col justify-between mx-auto shadow-md bg-gray-200 pb-3"
              key={image.id}
            >
              <img
                className="object-contain h-60 w-full"
                src={image.url}
                alt="Sunset in the mountains"
              />
              <div className="px-6 py-4">
                <div className="font-bold text-lg  text-center">
                  {image.title}
                </div>
              </div>
              <div className="px-6 py-2">
                <div className="text-base mb-2">By: {image.author}</div>
              </div>

              <div className="flex flex-row justify-center align-middle items-center">
                <div
                  className="text-lg w-20 text-center bg-blue-500 text-white py-2 rounded cursor-pointer"
                  onClick={() => {
                    setCampusPhotoOpen(true);
                    setCampusPhoto(image);
                    setAddNewOne(false);
                  }}
                >
                  Edit
                </div>
                <div
                  className="ml-5 text-lg w-20 text-center bg-red-500 text-white p-2 rounded cursor-pointer"
                  onClick={() => handleDelete(image.id)}
                >
                  Delete
                </div>
              </div>
            </div>
          ))}
          {/* add new campus photo */}
          {campusPhotos.length < 6 ? (
            <div
              className="max-w-sm w-full overflow-hidden flex justify-center items-center mx-auto shadow-lg bg-gray-300 pb-3 hover:bg-gray-400 hover:cursor-pointer"
              onClick={() => {
                setCampusPhotoOpen(true);
                setAddNewOne(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-16 h-16"
              >
                <path
                  fillRule="evenodd"
                  d="M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          ) : (
            <></>
          )}
        </div>
        {!(campusPhotos.length < 6) ? (
          <div className="pt-5 text-red-500 text-center font-sans">
            *Maximum number of slide show picture is reached.
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
};

export default Admin;
