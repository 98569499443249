const Loader = () => {
  let circleCommonClasses = "h-4 w-4 bg-main rounded-full";

  return (
    <div className="flex w-full h-screen justify-center items-center">
      <div className={`${circleCommonClasses} mr-2 animate-bounce`}></div>
      <div className={`${circleCommonClasses} mr-2 animate-bounce200`}></div>
      <div className={`${circleCommonClasses} animate-bounce400`}></div>
    </div>
  );
};

export default Loader;
