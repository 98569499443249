import React, { useEffect, useState } from "react";

import ParentProgress from "../../components/Accordion";

function BpaPPA() {
  useEffect(() => {
    document.title = "NIC | Admission";
  }, []);
  return (
    <>
      <head>
        <base href="/" />
      </head>
      <>
        <div className="flex flex-col gap-2">
          <div className="w-full bg-gray-300 px-8 py-10">
            <div className="flex flex-row justify-between align-middle items-center">
              <div className="text-lg font-bold">Course Overview</div>
              <img
                className="object-fill h-20"
                src="./assets/Partners/ppa transparent.png"
                alt="ppa"
              />
            </div>
            <div className="md:text-base text-sm mt-10">
              Our Bachelor in Professional Accounting program has a theoretical
              and practice- led approach covering the core aspects of accounting
              and finance including financial accounting, management accounting
              and finance. It also covers specialist areas such as auditing,
              taxation and strategy to a level equivalent to the ICAEW
              professional stage. Choosing this professional course can give you
              an advantage in a range of careers including: accountancy (both in
              practice and in industry),investment banking, asset management and
              insurance in organizations.
              <br />
              <br /> Bachelor in Professional Accounting is a Professional
              degree that students who completed &amp; passed Bachelor in
              Professional Accounting degree of 3 years can skip 9 ACCA
              subjects, by doing 4 last ACCA professional subjects to become a
              fellow or full ACCA holder or a Chartered Accountant.
            </div>
            <div className="md:ml-3 m-0 mt-10 flex flex-row justify-around">
              <div className="flex flex-row gap-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-10 w-10 my-auto text-main"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                    clipRule="evenodd"
                  />
                </svg>
                <div className="flex flex-col">
                  <p className="text-main font-bold"> Start Dates </p>
                  <p className="text-sm font-bold">March, July, September</p>
                </div>
              </div>
              <div className="flex flex-row gap-3">
                <svg
                  className="text-main w-10 h-10 my-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path
                    fill="currentColor"
                    d="M360 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24 0 90.965 51.016 167.734 120.842 192C75.016 280.266 24 357.035 24 448c-13.255 0-24 10.745-24 24v16c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24 0-90.965-51.016-167.734-120.842-192C308.984 231.734 360 154.965 360 64c13.255 0 24-10.745 24-24V24c0-13.255-10.745-24-24-24zm-64 448H88c0-77.458 46.204-144 104-144 57.786 0 104 66.517 104 144z"
                  ></path>
                </svg>
                <div className="flex flex-col">
                  <p className="text-main font-bold"> Duration </p>
                  <p className="text-sm font-bold"> 3 years </p>
                </div>
              </div>
            </div>
          </div>
          <ParentProgress header="Subjects">
            <div className="md:p-5 p-0">
              <div className="font-semibold text-lg">Course Modules</div>
              <div className="grid grid-cols-2 md:gap-3 gap-5 items-start mt-5">
                <div className="p-2">
                  <p className="font-bold md:text-base text-sm">
                    Year 1 (Semester 1)
                  </p>
                  <ul className="mt-5 md:text-base text-xs list-disc ">
                    <li>Business Economics</li>
                    <li>Financial Accounting for Business Decisions</li>
                    <li>Business Statistics</li>
                    <li>Professional Practices for Accountants</li>
                  </ul>
                </div>
                <div className="p-2">
                  <p className="font-bold md:text-base text-sm">
                    Year 1 (Semester 2)
                  </p>
                  <ul className="mt-5 md:text-base text-xs list-disc ">
                    <li>The Business Environment</li>
                    <li>Computer Aided Accounting Practices</li>
                    <li>Fundamentals of Finance</li>
                    <li>
                      Management Accounting for Planning, Decision Making and
                      Control
                    </li>
                  </ul>
                </div>
                <div className="mt-5 p-2">
                  <p className="font-bold md:text-base text-sm">
                    Year 2 (Semester 1)
                  </p>
                  <ul className="mt-5 md:text-base text-xs list-disc ">
                    <li>Audit and Assurance</li>
                    <li>Financial Management</li>
                    <li>Financial Reporting</li>
                    <li>Ethical Practices in Accounting</li>
                  </ul>
                </div>
                <div className="mt-5 p-2">
                  <p className="font-bold md:text-base text-sm">
                    Year 2 (Semester 2)
                  </p>
                  <ul className="mt-5 md:text-base text-xs list-disc ">
                    <li>Theoretical Aspects of Accounting</li>
                    <li>Business Law</li>
                    <li>Management Accounting</li>
                    <li>Tax Compliance</li>
                  </ul>
                </div>
                <div className="mt-5 p-2">
                  <p className="font-bold md:text-base text-sm">
                    Year 3 (Semester 1)
                  </p>
                  <ul className="mt-5 md:text-base text-xs list-disc ">
                    <li>Corporate Finance</li>
                    <li>Business Analysis</li>
                    <li>Financial Reporting 2</li>
                    <li>Financial Risk Management</li>
                  </ul>
                </div>
                <div className="mt-5 p-2">
                  <p className="font-bold md:text-base text-sm">
                    Year 3 (Semester 2)
                  </p>
                  <ul className="mt-5 md:text-base text-xs list-disc ">
                    <li>Advanced Auditing</li>
                    <li>Advanced Taxation</li>
                    <li>Corporate Financial Reporting</li>
                    <li>Performance Management</li>
                  </ul>
                </div>
              </div>
            </div>
          </ParentProgress>
          <ParentProgress header="Eligibility & Requirements">
            <div className="md:p-5 p-0">
              <div className="font-semibold text-lg">
                Admission Requirements
              </div>
              <ul className="mt-5 md:text-base text-sm list-disc p-2">
                <li>IGCSE/GCE 'A' Levels (or)</li>
                <li>High School Graduates </li>
              </ul>
              <div className="font-semibold text-lg mt-5">
                Documentations for application
              </div>
              <ul className="mt-5 md:text-base text-sm list-disc p-2">
                <li>A completed Application Form.</li>
                <li>Transcript of Academic Record</li>
                <li>2 Passport Photos</li>
                <li>Photocopy of NRC Card </li>
                <li>
                  Two (2) copies of Reference Forms to be filled in by the
                  academic (Minimum Lecturer Level) and immediate superior at
                  the workplace.
                </li>
              </ul>
            </div>
          </ParentProgress>
          <ParentProgress header="Career Track">
            <div className="">
              <ul className="md:text-base text-sm list-disc p-2">
                <li>Finance Manager</li>
                <li>Chartered Accountant</li>
                <li>Financial Controller</li>
                <li>Financial Investment advisor</li>
              </ul>
            </div>
          </ParentProgress>
          <ParentProgress header="Tuition Fees">
            <div className="md:p-5 p-0">
              <div className="flex flex-row justify-between">
                <div className="text-center p-2 ">Total</div>
                <div className="text-center p-2 ">
                  ~ 9800USD (Fees are subject to change without prior notice)
                </div>
              </div>
            </div>
          </ParentProgress>
          <ParentProgress header="How To Apply">
            <div className="px-5">
              Download application form
              <a
                download
                href=".\assets\Cetificates\PPA Bach in PA Application Form.pdf"
                className="text-blue-500 cursor-pointer hover:text-main"
              >
                &nbsp;here.&nbsp;
              </a>
              Contact Newton Internation College
            </div>
          </ParentProgress>
        </div>
      </>
    </>
  );
}

export default BpaPPA;
